import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class ParallaxBackground {
  private section: HTMLElement | null;
  private backgroundElement: HTMLElement | null;

  private options: {
    scrollSpeed: number;
    start: string;
    end: string;
    scrub: number | boolean;
  };

  constructor(
    sectionSelector: string,
    backgroundSelector: string,
    options = {
      scrollSpeed: 50,
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
    }
  ) {
    gsap.registerPlugin(ScrollTrigger);

    this.section = document.querySelector(sectionSelector);
    this.backgroundElement = document.querySelector(backgroundSelector);
    this.options = options;

    if (this.section && this.backgroundElement) {
      this.init();
    } else {
      console.error(`ParallaxBackground: No se encontraron los elementos con los selectores "${sectionSelector}" y/o "${backgroundSelector}"`);
    }
  }

  private init(): void {
    this.createAnimation();
    window.addEventListener("resize", () => this.handleResize());
    const resizeObserver = new ResizeObserver(() => this.handleResize());
    resizeObserver.observe(this.section as HTMLElement);
  }

  private createAnimation() {
    if (!this.section || !this.backgroundElement) return;

    // Calcular el valor Y basado en la velocidad
    const yValue = this.options.scrollSpeed > 0 ? -this.options.scrollSpeed : Math.abs(this.options.scrollSpeed);

    // Crear la animación con ScrollTrigger
    gsap.to(this.backgroundElement, {
      scrollTrigger: {
        trigger: this.section,
        start: this.options.start,
        end: this.options.end,
        scrub: this.options.scrub,
        invalidateOnRefresh: true, // Recalcular en cambios de tamaño
        markers: false,
      },
      y: yValue,
      ease: "none", // Movimiento lineal para efecto parallax
    });
  }

  private handleResize() {
    ScrollTrigger.refresh();
  }
}

export default ParallaxBackground;
