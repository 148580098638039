import { gsap } from "gsap/gsap-core";

class Whatsapp {
  logo = document.querySelector(".whatsapp-img");
  link = document.querySelector(".whatsapp");

  constructor() {
    this.init();
  }

  public init() {
    // Configuración inicial - logo oculto
    gsap.set(this.logo, { y: 48, opacity: 0 });

    // Creamos una timeline con configuración optimizada
    const tl = gsap.timeline({
      paused: true,
      defaults: {
        ease: "elastic", // Curva de easing más suave que bounce
        duration: 2, // Duración un poco más corta
      },
    });

    // Configuramos la animación en dos pasos para mejor fluidez
    tl.to(this.logo, {
      y: 0,
      opacity: 1,
      rotate: 360,
    });

    // Añadimos los event listeners con optimizaciones
    if (this.link) {
      this.link.addEventListener("mouseenter", () => {
        tl.timeScale(1).play(); // Velocidad normal al entrar
      });

      this.link.addEventListener("mouseleave", () => {
        tl.timeScale(1.5).reverse(); // Ligeramente más rápido al salir
      });
    }
  }
}

export default Whatsapp;
