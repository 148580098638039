// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

class FirebaseConfig {
  private analytics: Analytics;
  private firestoreApp: Firestore;

  constructor() {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyBScTuCq8miPptXuF-aIDf3vy06-EYakcc",
      authDomain: "maguitech-corp.firebaseapp.com",
      projectId: "maguitech-corp",
      storageBucket: "maguitech-corp.firebasestorage.app",
      messagingSenderId: "746938609807",
      appId: "1:746938609807:web:a5d82963c295a5bb7d40fa",
      measurementId: "G-G6BRRKWQRQ",
      // measurementId: "G-3TMRL1DG6N",
    };

    console.log("Analytics Apps: ", getApps());
    let app;
    const existingApps = getApps();
    if (!existingApps.length) {
      app = initializeApp(firebaseConfig);
      console.log('Initializing new Firebase App');
    } else {
      app = existingApps[0];
      console.log('Using existing Firebase App');
    }

    // Initialize Firebase
    this.analytics = getAnalytics(app);
    this.firestoreApp = getFirestore(app);

    setAnalyticsCollectionEnabled(this.analytics, true);

    // Descomentar para activar debug_mode en las métricas
    // window.gtag("config", firebaseConfig.measurementId, { debug_mode: true });

    console.log("Analytics Apps 2: ", getApps());
  }

  getFirestoreApp() {
    return this.firestoreApp;
  }

  getCreatedAnalytics() {
    return this.analytics;
  }
}

export default FirebaseConfig;
