// El efecto de texto se carga en la clase LanguageMenu
class ExperienceTextEffect {
  experienceText: HTMLElement | null = document.querySelector(".experience-text");
  pillars: HTMLElement | null = document.querySelector(".pillars");
  modals: HTMLElement | null = document.querySelector(".modals");
  constactForm: HTMLElement | null = document.querySelector(".contact-form");
  aboutSection: HTMLElement | null = document.querySelector(".about-section");
  footer: HTMLElement | null = document.querySelector(".footer");

  mainColor= "#c2185b";

  constructor() {
    this.init();
  }

  public init(): void {
    const circleRadius = 100;
    const experienceText = document.getElementById("experience-text");

    if (experienceText) {
      // Preservar el texto original
      const originalText = experienceText.textContent;
      const currentStyle = window.getComputedStyle(experienceText);

      // Crear un elemento contenedor para el efecto
      const effectContainer = document.createElement("div");
      effectContainer.style.position = "absolute";
      effectContainer.style.top = "0";
      effectContainer.style.left = "0";
      effectContainer.style.width = "100%";
      effectContainer.style.height = "100%";
      effectContainer.style.overflow = "hidden";
      effectContainer.style.pointerEvents = "none";

      // Crear un nuevo elemento para el efecto de texto colorido
      const colorEffect = document.createElement("div");
      colorEffect.textContent = originalText;
      colorEffect.style.position = "absolute";
      colorEffect.style.top = "0";
      colorEffect.style.left = "0";
      colorEffect.style.width = "100%";
      colorEffect.style.height = "100%";
      colorEffect.style.padding = currentStyle.padding;
      colorEffect.style.margin = currentStyle.margin;
      colorEffect.style.fontSize = currentStyle.fontSize;
      colorEffect.style.fontWeight = currentStyle.fontWeight;
      colorEffect.style.fontFamily = currentStyle.fontFamily;
      colorEffect.style.lineHeight = currentStyle.lineHeight;
      colorEffect.style.letterSpacing = currentStyle.letterSpacing;
      colorEffect.style.textAlign = currentStyle.textAlign;
      effectContainer.style.maskImage = "radial-gradient(circle 0px at 50% 50%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)";

      // En lugar de usar background-clip: text, usamos directamente color
      colorEffect.style.color = this.mainColor; // Color sólido azul-cyan
      // Alternativamente, podríamos usar webkit-text-fill-color para un gradiente
      // colorEffect.style.webkitTextFillColor = 'transparent';
      // colorEffect.style.background = 'linear-gradient(90deg, blue, cyan)';
      // colorEffect.style.webkitBackgroundClip = 'text';
      // colorEffect.style.backgroundClip = 'text';

      // Asegurarnos que el contenedor es relativo para posicionamiento absoluto
      experienceText.style.position = "relative";

      // Insertar los elementos en el DOM
      effectContainer.appendChild(colorEffect);
      experienceText.appendChild(effectContainer);

      // Evento para seguir el mouse y actualizar la máscara
      experienceText.addEventListener("mousemove", function (e) {
        // Obtenemos la posición relativa del mouse dentro del elemento
        const rect = experienceText.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Actualizamos la posición de la máscara radial
        const maskValue = `radial-gradient(circle ${circleRadius}px at ${x}px ${y}px, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 60%)`;
        effectContainer.style.webkitMaskImage = maskValue;
        effectContainer.style.maskImage = maskValue;
      });

      // Cuando el mouse entra, mostramos el efecto
      experienceText.addEventListener("mouseenter", function (e) {
        const rect = experienceText.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const maskValue = `radial-gradient(circle ${circleRadius}ppx at ${x}px ${y}px, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 60%)`;
        effectContainer.style.webkitMaskImage = maskValue;
        effectContainer.style.maskImage = maskValue;
      });

      // Cuando el mouse sale, ocultamos el efecto
      experienceText.addEventListener("mouseleave", function () {
        effectContainer.style.webkitMaskImage = "radial-gradient(circle 0px at 50% 50%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)";
        effectContainer.style.maskImage = "radial-gradient(circle 0px at 50% 50%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)";
      });
    }
  }
}

export default ExperienceTextEffect;
