import { Form } from "../services/form.service";
import { FormService } from "../services/form.service";

export class ManageFrom {
  nameInput: HTMLInputElement;
  emailInput: HTMLInputElement;
  interestSelect: HTMLSelectElement;
  messageTextarea: HTMLTextAreaElement;
  submitButton: HTMLButtonElement;
  formAlertSuccess: HTMLDivElement;
  formAlertError: HTMLDivElement;

  validationsConstants = {
    nameMinLength: 3,
    emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/,
    textareaMinLength: 10,
  };

  constructor(private formService: FormService) {
    this.nameInput = document.querySelector("#name") as HTMLInputElement;
    this.emailInput = document.querySelector("#email") as HTMLInputElement;
    this.interestSelect = document.querySelector(
      "#interest"
    ) as HTMLSelectElement;
    this.messageTextarea = document.querySelector(
      "#message"
    ) as HTMLTextAreaElement;

    this.submitButton = document.querySelector(
      "#sendForm"
    ) as HTMLButtonElement;
    this.submitButton.disabled = true;
    this.submitButton.addEventListener("click", async (event) => {
      event.preventDefault();
      this.sendForm();
    });

    this.formAlertSuccess = document.querySelector(
      ".form-alert--success"
    ) as HTMLDivElement;
    this.formAlertError = document.querySelector(
      ".form-alert--error"
    ) as HTMLDivElement;

    this.addListenersForValidation();

    console.log("ManageForm initialized");
  }

  getFormData(): Form {
    return {
      name: this.nameInput.value,
      email: this.emailInput.value,
      interest: this.interestSelect.value as Form["interest"],
      message: this.messageTextarea.value,
      createdDate: new Date(),
    };
  }

  clearForm() {
    this.nameInput.value = "";
    this.emailInput.value = "";
    this.interestSelect.value = "";
    this.messageTextarea.value = "";
  }

  addListenersForValidation() {
    this.nameInput.addEventListener("input", () => {
      this.validateForm();
      if (!this.validateNameInput()) {
        this.nameInput.classList.add("error");
      } else {
        this.nameInput.classList.remove("error");
      }
    });

    this.emailInput.addEventListener("input", () => {
      this.validateForm();
      if (!this.validateEmailInput()) {
        this.emailInput.classList.add("error");
      } else {
        this.emailInput.classList.remove("error");
      }
    });

    this.interestSelect.addEventListener("click", () => {
      this.validateForm();
      if (!this.interestSelect.value.trim()) {
        this.interestSelect.classList.add("error");
      } else {
        this.interestSelect.classList.remove("error");
      }
    });

    this.messageTextarea.addEventListener("input", () => {
      this.validateForm();
      if (!this.validateTextarea()) {
        this.messageTextarea.classList.add("error");
      } else {
        this.messageTextarea.classList.remove("error");
      }
    });
  }

  validateNameInput(): boolean {
    return (
      this.nameInput.value.trim().length >
      this.validationsConstants.nameMinLength
    );
  }

  validateEmailInput(): boolean {
    return this.validationsConstants.emailRegex.test(this.emailInput.value);
  }

  validateTextarea(): boolean {
    return (
      this.messageTextarea.value.trim().length >
      this.validationsConstants.textareaMinLength
    );
  }

  validateForm() {
    console.group("Validating form");
    console.log({
      name: this.nameInput.value,
      nameValidation: this.validateNameInput(),
      email: this.emailInput.value,
      interest: this.interestSelect.value,
      message: this.messageTextarea.value,
    });
    console.groupEnd();
    if (
      !this.validateNameInput() ||
      !this.validateEmailInput() ||
      !this.interestSelect.value.trim() ||
      !this.validateTextarea()
    ) {
      this.submitButton.disabled = true;
    } else {
      this.submitButton.disabled = false;
    }
  }

  async sendForm() {
    try {
      const form = this.getFormData();
      await this.formService.createForm(form);
      this.clearForm();
      this.formAlertSuccess.style.display = "block";
    } catch (error) {
      console.error("Error sending form: ", error);
      this.formAlertError.style.display = "block";
    }
  }
}
