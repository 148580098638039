import { Analytics, logEvent } from "firebase/analytics";
import { gsap } from "gsap";
import Flip from "gsap/Flip";

/**
 * Clase que maneja la animación y expansión de tarjetas en una interfaz web.
 * Utiliza GSAP para las animaciones y ajusta el diseño de las tarjetas según el dispositivo (desktop o móvil).
 *
 * Propiedades:
 * - `cardsContainer`: Contenedor principal de las tarjetas.
 * - `cardsColumn`: Columna que contiene las tarjetas.
 * - `cardsGrid`: Grid que contiene las tarjetas.
 * - `cards`: Lista de elementos de tarjetas.
 * - `modals`: Lista de elementos modales.
 * - `expandedContent`: Contenido expandido para desktop.
 * - `expandedContentMobile`: Contenido expandido para móvil.
 * - `activeCardId`: ID de la tarjeta actualmente activa.
 * - `isExpanded`: Indica si una tarjeta está expandida.
 * - `isAnimated`: Indica si una animación está en progreso.
 *
 * Métodos:
 * - `constructor()`: Inicializa la clase, registra el plugin GSAP y configura los elementos.
 * - `firstAnimation()`: Realiza la animación inicial de las tarjetas.
 * - `expandPanel()`: Expande el panel de la tarjeta seleccionada según el dispositivo.
 * - `expand(card: HTMLElement)`: Expande la tarjeta seleccionada con una animación y ajusta el diseño en desktop.
 * - `showExpandedContent(card: HTMLElement)`: Muestra el contenido expandido de la tarjeta seleccionada en desktop.
 * - `collapse()`: Cierra el panel expandido y restaura la vista de grid en desktop.
 * - `moveToLeft()`: Configura los event listeners para manejar la expansión de tarjetas en desktop.
 * - `moveUnderCard()`: Configura los event listeners para manejar la expansión de tarjetas en móvil.
 * - `expandMobile(card: HTMLElement)`: Expande el contenido de una tarjeta en móvil.
 * - `collapseMobile()`: Colapsa el contenido expandido en móvil.
 * - `showExpandedContentMobile(card: HTMLElement)`: Muestra el contenido expandido para una tarjeta específica en móvil.
 * - `animateModalContentMobile()`: Anima el contenido interno del modal en móvil.
 * - `animateModalContent()`: Anima el contenido interno del modal en desktop.
 * - `isMobileDevice()`: Verifica si el dispositivo actual es un dispositivo móvil.
 */
class CardsMotion {
  cardsContainer: HTMLElement | null;
  cardsColumn: HTMLElement | null;
  cardsGrid: HTMLElement | null;
  cards: NodeListOf<HTMLElement>;
  modals: NodeListOf<HTMLElement>;
  expandedContent: HTMLElement | null = null;
  expandedContentMobile: HTMLElement | null = null;
  activeCardId: string | null = null;
  isExpanded: boolean = false;
  isAnimated: boolean = false;

  constructor(private analyticsInstance: Analytics) {
    gsap.registerPlugin(Flip);

    this.cardsContainer = document.querySelector(".cards-container");
    this.cardsColumn = document.querySelector(".cards-column");
    this.cardsGrid = document.querySelector(".cards-grid");
    this.cards = document.querySelectorAll(".card");
    this.modals = document.querySelectorAll(".modal");
    this.expandedContent = document.querySelector(".expanded-content");

    this.firstAnimation();
    this.expandPanel();

    console.log("CardsMotion initialized");
  }

  /**
   * Realiza la primera animación de las tarjetas utilizando GSAP.
   *
   * La animación mueve las tarjetas desde una posición inicial con `y: 100`,
   * `scale: 0.5` y `opacity: 0` a una posición final con `y: 0`, `scale: 1`
   * y `opacity: 1`. La animación tiene una duración de 0.5 segundos, con un
   * retraso de 0.5 segundos antes de comenzar y un efecto de escalonamiento
   * de 0.2 segundos entre cada tarjeta.
   *
   * Al completar la animación, se restablece el estilo `transform` de cada
   * tarjeta.
   *
   * @private
   * @returns {void}
   */
  private firstAnimation(): void {
    gsap.fromTo(
      this.cards,
      {
        y: 100,
        scale: 0.5,
        opacity: 0,
        ease: "power1.out",
      },
      {
        y: 0,
        scale: 1,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        delay: 0.5,
        onComplete: () => {
          this.cards.forEach((card) => {
            card.style.transform = "";
          });
        },
      }
    );
  }

  /**
   * Expande el panel dependiendo del tipo de dispositivo.
   *
   * Si el dispositivo no es móvil, mueve el panel hacia la izquierda.
   * Si el dispositivo es móvil, mueve el panel debajo de la tarjeta.
   *
   * @private
   * @returns {void}
   */
  private expandPanel(): void {
    if (!this.isMobileDevice()) {
      this.moveToLeft();
    } else {
      this.moveUnderCard();
    }
  }

  /**
   * Expande la tarjeta seleccionada con una animación y ajusta el diseño en consecuencia.
   *
   * @param {HTMLElement} card - El elemento de la tarjeta que se va a expandir.
   *
   * Este método realiza los siguientes pasos:
   * 1. Establece la bandera `isAnimated` en true para indicar que una animación está en progreso.
   * 2. Registra el ID de la tarjeta clickeada y el ID de la tarjeta activa para propósitos de depuración.
   * 3. Ajusta la altura de `cardsColumn` para que coincida con la altura de `cardsGrid`.
   * 4. Anima la opacidad y escala de todas las tarjetas a 0, luego las oculta.
   * 5. Configura la cuadrícula a un diseño de una sola columna y establece el ancho de `cardsColumn`.
   * 6. Clona las tarjetas y usa un contenedor temporal para medir la altura final.
   * 7. Oculta las tarjetas originales y las añade a `cardsColumn`.
   * 8. Anima la altura de `cardsColumn` a la altura final medida.
   * 9. Muestra gradualmente las tarjetas mientras el contenedor se expande.
   * 10. Restablece la bandera `isAnimated` y establece `isExpanded` en true al completar.
   * 11. Llama a `showExpandedContent` para mostrar el contenido expandido.
   * @private
   * @returns {void}
   */
  private expand(card: HTMLElement): void {
    const tl = gsap.timeline({
      onComplete: () => {
        this.isAnimated = false;
        this.isExpanded = true;

        // Mostrar el contenido expandido una vez completada la animación
        this.showExpandedContent(card);
      },
    });

    this.isAnimated = true;

    console.log("(expand) Card clicked:", card.id);
    console.log("(expand) Active card:", this.activeCardId);

    if (this.cardsColumn && this.cardsGrid) {
      this.cardsColumn.style.height = this.cardsGrid.offsetHeight + "px";
    }

    tl.to(this.cards, {
      duration: 1,
      ease: "power1.out",
      opacity: 0,
      scale: 0.5,
      stagger: 0.2,
      // display: "none",
      onComplete: () => {
        if (this.cardsGrid && this.cardsColumn) {
          // Configurar el grid a una columna
          this.cardsGrid.style.gridTemplateColumns = "1fr";
          this.cardsColumn.style.maxWidth = "250px";
          this.cardsColumn.style.width = "250px";
          this.cardsColumn.style.flexShrink = "0";

          // Clonar las cards para calcular la altura final correctamente
          const cardClones = Array.from(this.cards).map(
            (card) => card.cloneNode(true) as HTMLElement
          );

          // Crear un contenedor temporal fuera del DOM visible para medir la altura real
          const tempContainer = document.createElement("div");
          tempContainer.style.position = "absolute";
          tempContainer.style.visibility = "hidden";
          tempContainer.style.width = "250px"; // Mismo ancho que cardsColumn
          tempContainer.style.display = "flex";
          document.body.appendChild(tempContainer);

          // Añadir los clones al contenedor temporal con escala normal
          cardClones.forEach((clone) => {
            clone.style.display = "block";
            clone.style.opacity = "1";
            clone.style.transform = "scale(1)"; // Importante: escala normal
            tempContainer.style.display = "flex";
            tempContainer.style.flexDirection = "column";
            tempContainer.style.gap = "20px";
            tempContainer.appendChild(clone);
          });

          // Forzar reflow para asegurar que se calculen las medidas
          const forceReflow = tempContainer.offsetHeight;

          // Ahora obtenemos la altura real que tendrá con todas las cards a escala 1
          const finalHeight = tempContainer.scrollHeight;

          // Ya no necesitamos el contenedor temporal
          document.body.removeChild(tempContainer);

          // Ocultar las cards originales
          this.cards.forEach((card) => {
            card.style.display = "none";
            if (this.cardsColumn) this.cardsColumn.appendChild(card);
          });

          // Asegurar que cardsColumn tiene una altura inicial definida
          const initialHeight = this.cardsColumn.offsetHeight || 0;
          this.cardsColumn.style.height = initialHeight + "px";
          this.cardsColumn.style.overflow = "hidden"; // Evitar saltos visuales

          // Animar la altura del contenedor
          gsap.to(this.cardsColumn, {
            height: finalHeight,
            duration: 2,
            ease: "power1.inOut",
            onComplete: () => {
              // Al finalizar, pasamos a height auto para que se ajuste naturalmente
              if (this.cardsColumn) {
                this.cardsColumn.style.height = "auto";
                this.cardsColumn.style.overflow = "";
              }
            },
          });

          // Mostrar las cards gradualmente mientras el contenedor se expande
          this.cards.forEach((card, index) => {
            gsap.to(card, {
              delay: 0.3 * index,
              duration: 1,
              display: "block",
              opacity: 1,
              scale: 1,
              onStart: () => {
                card.style.display = "block";
              },
              onComplete: () => {
                card.removeAttribute("style");
              },
            });
          });
        }
      },
    });
  }

  /**
   * Colapsa el contenido expandido y restaura la vista de grid de las tarjetas.
   *
   * Este método realiza las siguientes acciones:
   * 1. Verifica si los elementos `cardsGrid`, `cardsColumn` y `expandedContent` existen.
   * 2. Establece la propiedad `isAnimated` a `true` para indicar que una animación está en curso.
   * 3. Utiliza GSAP para animar la opacidad del contenido expandido a 0 y luego lo oculta.
   * 4. Crea una línea de tiempo de GSAP para manejar la animación de colapso.
   * 5. Oculta las tarjetas con una animación de opacidad y escala.
   * 6. Restaura la configuración del grid y mueve las tarjetas de vuelta al grid.
   * 7. Resetea los estilos de la columna de tarjetas.
   * 8. Limpia la clase activa de las tarjetas.
   * 9. Muestra las tarjetas en el grid con una animación de opacidad y escala.
   * 10. Limpia los estilos en línea de las tarjetas.
   * 11. Al completar la animación, establece `isAnimated` a `false`, `isExpanded` a `false` y `activeCardId` a `null`.
   *
   * @private
   * @returns {void}
   */
  private collapse(): void {
    if (!this.cardsGrid || !this.cardsColumn || !this.expandedContent) return;

    this.isAnimated = true;

    // Limpiamos el contenido expandido
    gsap.to(this.expandedContent, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        this.expandedContent!.innerHTML = "";
        this.expandedContent!.style.display = "none";
      },
    });

    // Animación para volver al estado de grid
    const tl = gsap.timeline({
      onComplete: () => {
        this.isAnimated = false;
        this.isExpanded = false;
        this.activeCardId = null;

        console.log({
          activeCard: this.activeCardId,
          isExpanded: this.isExpanded,
        });
      },
    });

    // Ocultar las cards primero
    tl.to(this.cards, {
      opacity: 0,
      scale: 0.5,
      duration: 0.5,
      stagger: 0.1,
      onComplete: () => {
        // Restaurar la configuración del grid
        this.cardsGrid!.style.gridTemplateColumns =
          "repeat(auto-fill, minmax(250px, 1fr))";

        // Mover las cards de vuelta al grid
        this.cards.forEach((card) => {
          if (this.cardsGrid) this.cardsGrid.appendChild(card);
        });

        // Resetear estilos de la columna
        if (this.cardsColumn) {
          this.cardsColumn.style.maxWidth = "";
          this.cardsColumn.style.width = "";
          this.cardsColumn.style.flexShrink = "";
        }

        // Limpiar la clase activa
        this.cards.forEach((card) => card.classList.remove("card-active"));
      },
    });

    // Mostrar las cards en el grid
    tl.to(this.cards, {
      opacity: 1,
      scale: 1,
      duration: 0.5,
      stagger: 0.1,
      onComplete: () => {
        // Limpiar estilos
        this.cards.forEach((card) => card.removeAttribute("style"));
      },
    });
  }

  /**
   * Mueve las tarjetas hacia la izquierda y gestiona la expansión y colapso de los paneles.
   *
   * Este método agrega un evento de clic a cada tarjeta. Cuando se hace clic en una tarjeta,
   * se verifica si el panel está expandido o no y se realizan las siguientes acciones:
   *
   * - Si el panel no está expandido, se expande el panel y se muestra el contenido de la tarjeta.
   * - Si el panel ya está expandido y se hace clic en la misma tarjeta, se colapsa el panel.
   * - Si el panel está expandido y se hace clic en otra tarjeta, se muestra el contenido de la nueva tarjeta.
   *
   * @private
   * @returns {void}
   */
  private moveToLeft(): void {
    this.cards.forEach((card) => {
      card.addEventListener("click", () => {
        console.log("Click desktop");

        // Hacer desaparecer el efecto de fondo de la sombra
        this.cards.forEach((card) => {
          card.style.setProperty("--before-opacity", "0");
        });

        if (this.cardsGrid && this.cardsContainer && !this.isAnimated) {
          // Controlar si el panel ya está expandido
          if (!this.isExpanded) {
            // Si no está expandido, expandir el panel
            this.expand(card);
            console.log("Expand");
          } else {
            if (this.activeCardId === card.id) {
              // Si ya está expandido y se hizo clic en la misma card, colapsar el panel
              this.collapse();
              console.log("Collapse");
            } else {
              // Si está expandido y se hizo clic en otra card, mostrar su contenido
              // this.activeCardId = card.id;
              this.showExpandedContent(card);
              console.log("Show content");
            }
          }
        }
      });
    });
  }

  /**
   * Maneja el evento de clic en cada tarjeta para expandir o colapsar su contenido.
   *
   * - Si el contenido expandido móvil no existe, lo inicializa.
   * - Si el panel no está expandido, expande el contenido de la tarjeta seleccionada.
   * - Si el panel ya está expandido y se hace clic en la misma tarjeta, colapsa el contenido.
   * - Si el panel está expandido y se hace clic en otra tarjeta, colapsa el contenido actual y expande el nuevo.
   *
   * @private
   * @returns {void}
   */
  private moveUnderCard(): void {
    this.cards.forEach((card) => {
      card.addEventListener("click", () => {
        console.log("Click mobile");
        if (this.cardsGrid && this.cardsContainer && !this.isAnimated) {
          // Inicializar expandedContentMobile si no existe
          if (!this.expandedContentMobile) {
            this.expandedContentMobile = card.querySelector(
              ".expanded-content-mobile"
            );
            this.expandedContentMobile!.style.display = "none";
            this.expandedContentMobile!.style.opacity = "0";
          }

          if (!this.isExpanded) {
            // Si no está expandido, expandir el panel para la card seleccionada
            this.expandMobile(card);
          } else if (this.activeCardId === card.id) {
            // Si ya está expandido y se hizo clic en la misma card, colapsar el panel
            this.collapseMobile();
          } else {
            // Si está expandido y se hizo clic en otra card diferente,
            // mostrar su contenido (cerrando el anterior y abriendo el nuevo)
            this.collapseMobile();

            // Esperar un poco antes de expandir la nueva card
            setTimeout(() => {
              // Inicializar expandedContentMobile si no existe
              if (!this.expandedContentMobile) {
                this.expandedContentMobile = card.querySelector(
                  ".expanded-content-mobile"
                );
                this.expandedContentMobile!.style.display = "none";
                this.expandedContentMobile!.style.opacity = "0";
              }

              this.expandMobile(card);
                console.log("Debería moverse la vista a la card");
                // Simular el comportamiento de un enlace anclado desplazando la vista a la tarjeta seleccionada
                window.location.hash = `#${card.id}`;
            }, 300); // Pequeño retraso para que termine la animación de colapso
          }
        }
      });
    });
  }

  /**
   * Muestra el contenido expandido de una tarjeta específica.
   *
   * @param card - El elemento HTML de la tarjeta que se ha seleccionado.
   *
   * Este método realiza las siguientes acciones:
   * 1. Verifica si hay contenido expandido disponible.
   * 2. Establece la tarjeta activa y elimina la clase "card-active" de todas las tarjetas.
   * 3. Determina el ID del modal a mostrar basado en el ID de la tarjeta seleccionada.
   * 4. Utiliza GSAP para animar la desaparición del contenido existente y la aparición del nuevo contenido.
   * 5. Clona el contenido del modal correspondiente y lo inserta en el contenedor de contenido expandido.
   * 6. Anima la aparición del nuevo contenido y agrega la clase "card-active" a la tarjeta seleccionada.
   * 7. Llama al método `animateModalContent` para animar el contenido del modal.
   *
   * @private
   * @returns {void}
   */
  private showExpandedContent(card: HTMLElement): void {
    if (!this.expandedContent) return;

    // Hacer desaparecer el efecto de fondo de la sombra
    this.cards.forEach((card) => {
      card.style.setProperty("--before-opacity", "0");
    });

    this.activeCardId = card.id;
    this.cards.forEach((card) => card.classList.remove("card-active"));
    console.log("(showExpandedContent) Show content for:", card.id);
    console.log("(showExpandedContent) Active card:", this.activeCardId);

    let modalId = "";

    switch (card.id) {
      case "card1":
        modalId = "aiAutomation";
        break;
      case "card2":
        modalId = "cloudEngineering";
        break;
      case "card3":
        modalId = "web3Infra";
        break;
      case "card4":
        modalId = "training";
    }

    const tl = gsap.timeline();

    // Limpiamos el contenido existente
    tl.to(this.expandedContent, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        this.expandedContent!.innerHTML = "";
        this.expandedContent!.appendChild(
          document.getElementById(modalId)!.cloneNode(true) as HTMLElement
        );

        // Restaurar el efecto de fondo de la sombra
        this.cards.forEach((card) => {
          card.style.setProperty("--before-opacity", "1");
        });

        // Enviamos evento a analytics
        this.dispatchAnalyticsEvents(card);
      },
    });

    // Animamos la aparición del contenido
    tl.to(this.expandedContent, {
      opacity: 1,
      display: "block",
      duration: 0.5,
      stagger: 0.2,
      onComplete: () => {
        card.classList.add("card-active");

        // Disparar eventos de análisis
        // this.dispatchAnalyticsEvents(card);

        // Animar el contenido del modal
        this.animateModalContent();
      },
    });
  }

  /**
   * Expande una tarjeta en la vista móvil mostrando contenido adicional.
   *
   * @param {HTMLElement} card - El elemento de la tarjeta que se va a expandir.
   *
   * Esta función realiza las siguientes acciones:
   * - Verifica si hay contenido expandido móvil disponible y si no hay animaciones en curso.
   * - Identifica la posición de la tarjeta en el grid.
   * - Determina el contenido modal a mostrar basado en el ID de la tarjeta.
   * - Posiciona e inserta el contenido expandido después de la tarjeta.
   * - Clona el contenido del modal y lo añade al contenido expandido.
   * - Anima la aparición del contenido expandido.
   * - Marca la tarjeta como activa y anima el contenido interno del modal.
   *
   * @private
   * @returns {void}
   */
  private expandMobile(card: HTMLElement): void {
    console.log("Expand mobile", this.expandedContentMobile);
    if (!this.expandedContentMobile || this.isAnimated) return;
    console.log(card.id);
    this.isAnimated = true;

    // Identificar la posición de la card en el grid
    const cardRect = card.getBoundingClientRect();
    const containerRect = this.cardsGrid!.getBoundingClientRect();

    // Identificar el contenido modal a mostrar
    let modalId = "";
    switch (card.id) {
      case "card1":
        modalId = "aiAutomation";
        break;
      case "card2":
        modalId = "cloudEngineering";
        break;
      case "card3":
        modalId = "web3Infra";
        break;
      case "card4":
        modalId = "training";
        break;
    }

    // Posicionar e insertar el contenido expandido después de la card
    this.expandedContentMobile.style.display = "none";
    this.expandedContentMobile.style.opacity = "0";

    // Limpiar el contenido existente
    this.expandedContentMobile.innerHTML = "";

    // Clonar el contenido del modal y añadirlo al contenido expandido
    const modalContent = document.getElementById(modalId);
    if (modalContent) {
      this.expandedContentMobile.appendChild(
        modalContent.cloneNode(true) as HTMLElement
      );
    }

    // Animar la aparición del contenido expandido
    const tl = gsap.timeline({
      onComplete: () => {
        this.isAnimated = false;
        this.isExpanded = true;
        this.activeCardId = card.id;

        // Aplicar clase activa a la card
        this.cards.forEach((c) => c.classList.remove("card-active"));
        card.classList.add("card-active");

        // Animar el contenido interno del modal
        this.animateModalContentMobile();

        // Enviamos evento a analytics
        this.dispatchAnalyticsEvents(card);
      },
    });

    tl.to(this.expandedContentMobile, {
      display: "block",
      opacity: 0,
      height: "auto",
      duration: 0,
    }).to(this.expandedContentMobile, {
      opacity: 1,
      duration: 0.5,
      ease: "power1.out",
    });
  }

  /**
   * Colapsa el contenido expandido en dispositivos móviles.
   *
   * Este método realiza las siguientes acciones:
   * - Verifica si el contenido expandido móvil, el estado expandido y el estado de animación son válidos.
   * - Si las condiciones son válidas, inicia una animación para ocultar el contenido expandido.
   * - Al completar la animación, oculta el contenido expandido, lo limpia y lo establece como nulo.
   * - Elimina la clase activa de todas las tarjetas.
   * - En dispositivos móviles, elimina el estado persistente de hover forzando un reflow.
   * - Restablece el estado de la tarjeta activa, el estado expandido y el estado de animación.
   *
   * @private
   * @returns {void}
   */
  private collapseMobile(): void {
    console.log("Collapse mobile");
    if (!this.expandedContentMobile || !this.isExpanded || this.isAnimated)
      return;

    this.isAnimated = true;

    // Animar la desaparición del contenido expandido
    gsap.to(this.expandedContentMobile, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        if (this.expandedContentMobile) {
          this.expandedContentMobile.style.display = "none";
          this.expandedContentMobile.innerHTML = "";
          this.expandedContentMobile = null;
        }

        // Quitar la clase activa de todas las cards
        this.cards.forEach((card) => {
          card.classList.remove("card-active");

          // Eliminamos el estado hover persistente en móviles
          if (this.isMobileDevice()) {
            card.blur();
            // Forzar reflow para eliminar cualquier estado :hover persistente
            card.style.display = "none";
            void card.offsetHeight; // Trigger reflow
            card.style.display = "";
          }
        });

        this.activeCardId = null;
        this.isExpanded = false;
        this.isAnimated = false;
      },
    });
  }

  /**
   * Anima el contenido del modal en la vista móvil.
   *
   * Esta función anima la imagen y los elementos de texto dentro del modal
   * expandido en la vista móvil utilizando la biblioteca GSAP.
   *
   * - La imagen se anima desde una posición vertical desplazada y con opacidad cero
   *   hasta su posición original con opacidad completa.
   * - Los elementos de texto se animan desde una posición horizontal desplazada y con
   *   opacidad cero hasta su posición original con opacidad completa, con un efecto
   *   de retroceso y un desfase entre cada elemento.
   *
   * @private
   * @returns {void}
   */
  private animateModalContentMobile(): void {
    if (!this.expandedContentMobile) return;

    const modal = this.expandedContentMobile.querySelector(
      ".modal"
    ) as HTMLElement;
    const img = this.expandedContentMobile.querySelector("img");
    const text = this.expandedContentMobile.querySelector(
      ".modal-content__text"
    ) as HTMLElement;

    // Animar la imagen
    if (img) {
      gsap.fromTo(
        img,
        {
          y: "-30px",
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
        }
      );
    }

    // Animar los elementos de texto
    if (text?.hasChildNodes()) {
      text.style.opacity = "1";
      gsap.fromTo(
        text.children,
        {
          x: "50px",
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.2,
          ease: "back",
        }
      );
    }
  }

  /**
   * Anima el contenido de un modal utilizando GSAP.
   *
   * Esta función selecciona los elementos de imagen y texto dentro del contenido expandido del modal
   * y aplica animaciones de entrada utilizando la biblioteca GSAP.
   *
   * - La imagen se anima desde una posición vertical fuera de la vista con opacidad 0 a su posición original con opacidad 1.
   * - Si el texto tiene nodos hijos, se anima cada hijo desde una posición horizontal fuera de la vista con opacidad 0 a su posición original con opacidad 1.
   *
   * @private
   * @returns {void}
   */
  private animateModalContent(): void {
    const modal = this.expandedContent!.querySelector(".modal") as HTMLElement;
    const img = this.expandedContent!.querySelector("img");
    const text = this.expandedContent!.querySelector(
      ".modal-content__text"
    ) as HTMLElement;
    gsap.fromTo(
      img,
      {
        y: "-100%",
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
      }
    );
    if (text?.hasChildNodes()) {
      console.log(text.children);
      text.style.opacity = "1";
      gsap.fromTo(
        text.children,
        {
          x: "100%",
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.3,
          ease: "back",
        }
      );
    }
  }

  /**
   * Comprueba si el dispositivo actual es un dispositivo móvil.
   *
   * Esta función utiliza dos enfoques para determinar si el dispositivo es móvil:
   * 1. Verifica si el `userAgent` del navegador coincide con patrones comunes de dispositivos móviles.
   * 2. Verifica si el tamaño de la pantalla es menor o igual a 768 píxeles de ancho.
   *
   * @returns {boolean} `true` si el dispositivo es móvil, `false` en caso contrario.
   */
  private isMobileDevice(): boolean {
    const breakpointXS = 480;
    const breakpointSM = 768;
    const breakpointMD = 1024;
    const breakpointLG = 1200;
    const breakpointXL = 1440;

    // Comprueba si navigator.userAgent está disponible
    if (!navigator || !navigator.userAgent) {
      return false;
    }

    // Expresión regular para detectar dispositivos móviles comunes
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    // Comprueba si el user agent coincide con algún patrón móvil
    const isMobileUserAgent = mobileRegex.test(navigator.userAgent);

    // Comprueba el tamaño de pantalla (enfoque adicional)
    const isMobileScreenSize = window.innerWidth <= breakpointXS;

    // Devuelve true si coincide con algún patrón móvil o el tamaño es de móvil
    // El uso de mobileRegex da problemas en algunos casos, haciendo que el contenido no se muestre de forma correcta
    return isMobileScreenSize; // || isMobileUserAgent
  }

  /**
   * Envía eventos de análisis a Firebase cuando se hace clic en una tarjeta.
   *
   * @param {HTMLElement} card - El elemento de la tarjeta que se ha clicado.
   *
   * Este método realiza las siguientes acciones:
   * 1. Registra un evento de clic en la tarjeta en la consola.
   * 2. Envía un evento a Firebase Analytics con el ID de la tarjeta clicada y la marca de tiempo actual.
   *
   * @private
   * @returns {void}
   */
  private dispatchAnalyticsEvents(card: HTMLElement): void {
    let cardName: string;
    switch (card.id) {
      case "card1":
        cardName = "AIAutomation";
        break;
      case "card2":
        cardName = "CloudEngineering";
        break;
      case "card3":
        cardName = "Web3Infrastructure";
        break;
      case "card4":
        cardName = "Training";
        break;
      default:
        cardName = "UnknownCard";
    }
    console.group("Analytics Event Dispatch");
    console.log(`Evento disparado en la tarjeta: ${card.id}`);
    console.log(`ID tarjeta: ${card.id}`);
    console.log(`Nombre tarjeta: ${cardName}`);
    console.log(`Marca de tiempo: ${Date.now()}`);
    console.trace(`Disparo evento en: ${card.id}`);
    console.groupEnd();

    logEvent(this.analyticsInstance, "clicked_card-" + cardName, {
      opened_Card: cardName,
      timestamp: Date.now(),
    });
  }
}

export default CardsMotion;
