import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class About {
  aboutLeftContainer: HTMLElement;
  aboutLeftCards: NodeListOf<HTMLElement>;
  aboutRightContainer: HTMLElement;
  aboutRigthCards: HTMLCollection;

  constructor() {
    console.log("About class initialized");
    gsap.registerPlugin(ScrollTrigger);

    this.aboutLeftContainer = document.querySelector(
      ".about-info"
    ) as HTMLElement;
    this.aboutLeftCards = document.querySelectorAll(
      ".about-card"
    ) as NodeListOf<HTMLElement>;
    this.aboutRightContainer = document.querySelector(
      ".about-map"
    ) as HTMLElement;
    this.aboutRigthCards = this.aboutRightContainer.children;

    this.init();
  }

  private init(): void {
    gsap.set(this.aboutLeftCards, { opacity: 0, x: -200});
    gsap.set(this.aboutRigthCards, { opacity: 0, x: 200});

    gsap.to(this.aboutLeftCards, {
      scrollTrigger: {
        trigger: this.aboutLeftContainer,
        start: "top+=5% bottom",
        end: "center center",
        scrub: 1,
        toggleActions: "play none none none",
        markers: false,
      },
      opacity: 1,
      x: 0,
      duration: 0.5,
      stagger: 0.5,
    });

    gsap.to(this.aboutRigthCards, {
      scrollTrigger: {
        trigger: this.aboutRightContainer,
        start: "top+=5% bottom",
        end: "center center",
        scrub: 1,
        toggleActions: "play none none none",
        markers: false,
      },
      opacity: 1,
      x: 0,
      duration: 0.5,
      stagger: 0.5,
    });
  }
}

export default About;
