import { createChat } from "@n8n/chat";

export class ChatBot {
  constructor() {
    this.changeStyle();
    this.initChatBot();
  }

  initChatBot() {
    const hour = new Date().getHours();
    let saludo = "";

    if (hour >= 6 && hour < 12) {
      saludo = "¡Buenos días! 👋";
    } else if (hour >= 12 && hour < 19) {
      saludo = "¡Buenas tardes! 👋";
    } else {
      saludo = "¡Buenas noches! 👋";
    }

    createChat({
      webhookUrl:
        "https://automation.maguitech.com/webhook/a889d2ae-2159-402f-b326-5f61e90f602e/chat",
      webhookConfig: {
        method: "POST",
        headers: {},
      },
      target: "#n8n-chat",
      mode: "window",
      metadata: {},
      showWelcomeScreen: false,
      defaultLanguage: "en",
      initialMessages: [saludo, "Mi nombre es Magui, en que puedo ayudarte?"],
      i18n: {
        en: {
          title: "🤖 MaguiBot 🤖",
          subtitle:
            "Soy Magui, el Bot de Maguitech. Estoy disponible las 24h para resolver qualquier duda que tengas.",
          footer: "",
          getStarted: "Nueva conversación",
          inputPlaceholder: "Pregúntame, estoy para ayudarte..",
          closeButtonTooltip: "Cerrar chat",
        },
      },
    });
  }

  changeStyle() {
    const target = document.querySelector("#n8n-chat");
    const buttonImg = document.querySelector(
      ".chatbot-link__img"
    ) as HTMLElement;
    const buttonText = document.querySelector(
      ".chatbot-link__text"
    ) as HTMLElement;

    if (target) {
      const observer = new MutationObserver((mutations, observer) => {
        for (const mutation of mutations) {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            // Se han añadido cosas al DOM
            const windowChat = target?.querySelector(
              ".chat-window"
            ) as HTMLElement;
            const targetToggleChat = target?.querySelector(
              ".chat-window-toggle"
            ) as HTMLElement;

            targetToggleChat.style.display = "none";

            const newToggleChat = document.querySelector(
              ".chatbot-link"
            ) as HTMLElement;
            newToggleChat.addEventListener("click", () => {
              windowChat.style.display =
                windowChat.style.display === "none" ? "block" : "none";
              buttonImg.classList.toggle("active");
              buttonText.classList.toggle("active");
            });
          }

          observer.disconnect(); // Desconectar el observer después de aplicar el estilo
        }
      });
      observer.observe(target, {
        childList: true,
        subtree: true,
      });
    }
  }
}
