// Importamos GSAP
import { gsap } from "gsap";
import ExperienceTextEffect from "./experience-text-effect";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "../../models/supported-languages.type";

// Definición de tipos para los idiomas soportados
// const SUPPORTED_LANGUAGES = ["en", "es", "et", "zh"] as const;

// export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

// Interfaz para representar las traducciones del botón
interface ButtonTranslations {
  en: string;
  es: string;
  et: string;
  zh: string;
}

// Clase principal para gestionar el menú de idiomas
class LanguageMenu {
  // Elementos del DOM
  private menuContainer: HTMLElement;
  private menuButton: HTMLElement;
  private dropdown: HTMLElement;
  private languageItems: NodeListOf<HTMLElement>;

  // Estado actual
  private currentLanguage: SupportedLanguage = "en";
  private isMenuOpen: boolean = false;

  // Traducciones para el botón principal
  private buttonTranslations: ButtonTranslations = {
    en: "Language",
    es: "Idioma",
    et: "Keel",
    zh: "语言",
  };

  /**
   * Constructor
   * @param menuId - ID del contenedor del menú en el DOM
   */
  constructor(menuId: string, private experienceText: ExperienceTextEffect) {
    // Obtenemos las referencias a los elementos del DOM
    this.menuContainer = document.getElementById(menuId) as HTMLElement;
    this.menuButton = this.menuContainer.querySelector(
      ".language-menu__button"
    ) as HTMLElement;
    this.dropdown = this.menuContainer.querySelector(
      ".language-menu__dropdown"
    ) as HTMLElement;
    this.languageItems = this.menuContainer.querySelectorAll(
      ".language-menu__item"
    ) as NodeListOf<HTMLElement>;

    if (!this.menuContainer) {
      console.error(`Elemento con ID "${menuId}" no encontrado`);
      return;
    }

    // Configuramos el estado inicial del menú
    this.setupInitialState();

    // Inicializamos los eventos
    this.initializeEvents();

    // Recuperamos el idioma guardado o usamos el del navegador
    this.initializeLanguage();
  }

  /**
   * Configura el estado inicial de los elementos del menú
   */
  private setupInitialState(): void {
    // Configuración inicial con GSAP
    gsap.set(this.dropdown, {
      autoAlpha: 0, // Combina opacity y visibility
      y: -10, // Posición inicial ligeramente arriba para la animación
      display: "none", // Aseguramos que esté oculto inicialmente
    });
  }

  /**
   * Inicializa los eventos del menú
   */
  private initializeEvents(): void {
    // Evento para mostrar/ocultar el menú al hacer clic en el botón
    this.menuButton.addEventListener("click", (e) => {
      e.stopPropagation(); // Evita que el clic se propague al document
      this.toggleMenu();
    });

    // Eventos para los elementos del menú
    this.languageItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        e.stopPropagation(); // Evita que el clic se propague
        const lang = item.getAttribute("data-lang") as SupportedLanguage;
        this.changeLanguage(lang);
        this.closeMenu();
        console.log("click al boton");
      });
    });

    // Cerrar el menú al hacer clic fuera
    document.addEventListener("click", () => {
      if (this.isMenuOpen) {
        this.closeMenu();
      }
    });

    // Cerrar el menú al presionar ESC
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && this.isMenuOpen) {
        this.closeMenu();
      }
    });
  }

  /**
   * Inicializa el idioma basándose en localStorage o el idioma del navegador
   */
  private initializeLanguage(): void {
    // Intentamos obtener el idioma guardado
    const savedLanguage = localStorage.getItem(
      "preferredLanguage"
    ) as SupportedLanguage;

    if (savedLanguage && this.isValidLanguage(savedLanguage)) {
      this.currentLanguage = savedLanguage;
    } else {
      // Si no hay idioma guardado, intentamos detectar el del navegador
      const browserLang = this.getBrowserLanguage();
      this.currentLanguage = browserLang;
    }

    // Aplicamos el idioma actual
    this.applyLanguage(this.currentLanguage);

    // Actualizamos el texto del botón
    this.updateButtonText();

    // Marcamos como activo el elemento del idioma actual
    this.highlightActiveLanguage();
  }

  /**
   * Verifica si el idioma es válido dentro de los soportados
   */
  private isValidLanguage(lang: string): lang is SupportedLanguage {
    return SUPPORTED_LANGUAGES.includes(lang as SupportedLanguage);
  }
  /**
   * Obtiene el idioma del navegador y lo mapea a uno de los soportados
   * Por defecto devuelve 'en' si no encuentra coincidencia
   */
  private getBrowserLanguage(): SupportedLanguage {
    const browserLang = navigator.language.split("-")[0];

    // Verificamos si el idioma del navegador está entre los soportados
    if (this.isValidLanguage(browserLang)) {
      return browserLang;
    }

    // Por defecto inglés
    return "en";
  }

  /**
   * Cambia el idioma actual de la aplicación
   */
  private changeLanguage(lang: SupportedLanguage): void {
    if (this.currentLanguage === lang) return;

    this.currentLanguage = lang;

    // Guardamos la preferencia en localStorage
    localStorage.setItem("preferredLanguage", lang);

    // Aplicamos los cambios de idioma
    this.applyLanguage(lang);

    // Actualizamos el texto del botón
    this.updateButtonText();

    // Actualizamos el elemento activo
    this.highlightActiveLanguage();
  }

  /**
   * Aplica los cambios de idioma a todos los elementos con atributos data-lang-xx
   */
  private applyLanguage(lang: SupportedLanguage): void {
    this.applyTranslations(lang);
  }

  /**
   * Implementación básica del cambio de idioma (si no existe la función global)
   */
  public applyTranslations(lang: SupportedLanguage): void {
    // Seleccionamos todos los elementos con atributos data-lang-XX
    const elements = document.querySelectorAll(`[data-lang-${lang}]`);

    elements.forEach((elem) => {
      const translation = elem.getAttribute(`data-lang-${lang}`);
      if (!translation) return;

      const tag = elem.tagName.toLowerCase();

      // Aplicamos la traducción según el tipo de elemento
      if (tag === "input" || tag === "textarea") {
        (elem as HTMLInputElement).placeholder = translation;
      } else if (tag === "select") {
        const placeholderOption = (elem as HTMLSelectElement).querySelector(
          'option[value=""]'
        );
        if (placeholderOption) {
          placeholderOption.textContent = translation;
        }

        // Traducimos también las opciones
        const allOptions = (elem as HTMLSelectElement).querySelectorAll(
          "option"
        );
        allOptions.forEach((option) => {
          const optTranslation = option.getAttribute(`data-lang-${lang}`);
          if (optTranslation) {
            option.textContent = optTranslation;
          }
        });
      } else {
        elem.textContent = translation;
      }
    });

    // Comentar o descomentar para inicializar el efecto de texto
    // Inicializamos el efecto de texto
    // this.experienceText.init();
  }

  /**
   * Actualiza el texto del botón según el idioma actual
   */
  private updateButtonText(): void {
    if (!this.menuButton) return;

    const textNode = this.menuButton.childNodes[0]; // Primer nodo de texto
    if (textNode && textNode.nodeType === Node.TEXT_NODE) {
      textNode.nodeValue = this.buttonTranslations[this.currentLanguage] + " "; // Espacio antes del span
    }
  }

  /**
   * Marca como activo el elemento del idioma actual
   */
  private highlightActiveLanguage(): void {
    // Quitamos la clase activa de todos
    this.languageItems.forEach((item) => {
      item.classList.remove("language-menu__item--active");
    });

    // Añadimos la clase al idioma actual
    const activeItem = this.menuContainer.querySelector(
      `.language-menu__item[data-lang="${this.currentLanguage}"]`
    );
    if (activeItem) {
      activeItem.classList.add("language-menu__item--active");
    }
  }

  /**
   * Alterna la visibilidad del menú desplegable
   */
  private toggleMenu(): void {
    if (this.isMenuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  /**
   * Abre el menú desplegable con animación GSAP
   */
  private openMenu(): void {
    if (this.isMenuOpen) return;

    this.isMenuOpen = true;
    this.menuContainer.classList.add("language-menu--open");

    // Animación con GSAP
    gsap.set(this.dropdown, { display: "block" });
    gsap.fromTo(
      this.dropdown,
      { autoAlpha: 0, y: -10 },
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.out",
      }
    );

    // Animamos la flecha del botón
    gsap.to(this.menuButton.querySelector(".language-menu__button span"), {
      rotation: 180,
      duration: 0.3,
    });
  }

  /**
   * Cierra el menú desplegable con animación GSAP
   */
  private closeMenu(): void {
    if (!this.isMenuOpen) return;

    this.isMenuOpen = false;

    // Animación con GSAP
    gsap.to(this.dropdown, {
      autoAlpha: 0,
      y: -10,
      duration: 0.2,
      ease: "power2.in",
      onComplete: () => {
        gsap.set(this.dropdown, { display: "none" });
        this.menuContainer.classList.remove("language-menu--open");
      },
    });

    // Animamos la flecha del botón
    gsap.to(this.menuButton.querySelector(".language-menu__button span"), {
      rotation: 0,
      duration: 0.3,
    });
  }
}

export default LanguageMenu;
