// import demosData from "../../demos-data/demos.json";
import { Idemo } from "../../models/demo.model";
import { CardsId } from "../../models/cardsId.enum";
import { collection, Firestore, getDocs } from "firebase/firestore";
import LanguageMenu from "../effects/language-menu";
import { SUPPORTED_LANGUAGES, SupportedLanguage } from "../../models/supported-languages.type";

export default class InsertDemos {
  private cardsId: string[] = Object.values(CardsId);
  // private demos: Idemo[] = demosData.useCases;
  private demos: Idemo[] = [];
  private containers: HTMLElement[] = [];

  constructor(
    private firestoreInstance: Firestore,
    private languageMenu: LanguageMenu
  ) {
    this.cardsId.forEach((cardId) => {
      const container = document.querySelector(`#${cardId}`) as HTMLElement;
      this.containers.push(container);
    });

    this.init();
  }

  async getAllDemos() {
    const demosRef = collection(this.firestoreInstance, "demos");
    const snapshot = await getDocs(demosRef);

    const demos = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        cardId: data.cardId || "",
        title: data.title || { en: "", es: "", et: "", zh: "" },
        description: data.description || { en: "", es: "", et: "", zh: "" },
        listItems: data.listItems || [],
        imgURL: data.imgURL || null,
        demoURL: data.demoURL || "",
      } as Idemo;
    });

    console.log(demos);
    return demos;
  }

  init() {
    this.containers.forEach((container) => {
      // Para cada card seleccionamos el contenedor de demos
      const containerDemo = container.querySelector(
        ".modal-content__demos"
      ) as HTMLElement;

      if (containerDemo) {
        const id = container.getAttribute("id") as string;
        // Obtenemos todos los demos de Firestore
        this.getAllDemos().then((demos) => {
          this.demos = demos;
          console.log("Demos cargadas desde Firestore");

          const demosList = this.demos.filter((demo) => demo.cardId === id);

          if (demosList) {
            demosList.forEach((demo) => {
              // Creamos el contenedor de la demo
              const contentDiv = document.createElement("div");
              contentDiv.classList.add("modal-content__demos--demo");

              const title = document.createElement("h4");
              title.setAttribute("data-lang-en", demo.title.en);
              title.setAttribute("data-lang-es", demo.title.es);
              title.setAttribute("data-lang-et", demo.title.et);
              title.setAttribute("data-lang-zh", demo.title.zh);
              title.innerText = demo.title.en;

              const description = document.createElement("p");
              description.setAttribute("data-lang-en", demo.description.en);
              description.setAttribute("data-lang-es", demo.description.es);
              description.setAttribute("data-lang-et", demo.description.et);
              description.setAttribute("data-lang-zh", demo.description.zh);
              description.innerText = demo.description.en;

              contentDiv.appendChild(title);
              contentDiv.appendChild(description);

              containerDemo.appendChild(contentDiv);

              // Si hay lista de ítems, la añadimos
              const listItems = demo.listItems;
              if (listItems) {
                const list = document.createElement("ul");
                listItems.forEach((item) => {
                  const li = document.createElement("li");
                  li.setAttribute("data-lang-en", item.text.en);
                  li.setAttribute("data-lang-es", item.text.es);
                  li.setAttribute("data-lang-et", item.text.et);
                  li.setAttribute("data-lang-zh", item.text.zh);
                  li.innerText = item.text.en;
                  list.appendChild(li);
                });
                contentDiv.appendChild(list);
              }

              // Añadimos el botón para ir a la demo
              const demoButton = document.createElement("a");
              demoButton.setAttribute("href", demo.demoURL || "#");
              demoButton.classList.add("demo-button");
              demoButton.setAttribute("data-lang-en", "See Demo");
              demoButton.setAttribute("data-lang-es", "Ver Demo");
              demoButton.setAttribute("data-lang-et", "Vaata demot");
              demoButton.setAttribute("data-lang-zh", "查看演示");
              demoButton.innerText = "See Demo";

              contentDiv.appendChild(demoButton);
            });
          }
          const localStorageLang = localStorage.getItem("preferredLanguage");
          if (
            SUPPORTED_LANGUAGES.includes(localStorageLang as SupportedLanguage)
          ) {
            console.log("Cargando idioma desde localStorage:", localStorageLang);
            this.languageMenu.applyTranslations(
              localStorageLang as SupportedLanguage
            );
          }
        });
      }
    });
  }
}
