import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

class ContactMotion {
  section: HTMLElement;
  title: HTMLElement;
  text: HTMLElement;

  form: HTMLElement;
  inputName: HTMLElement;
  inputEmail: HTMLElement;
  selectInterest: HTMLElement;
  textaareaMessage: HTMLElement;
  submitButton: HTMLElement;

  whatsappLink: HTMLElement;

  constructor() {
    gsap.registerPlugin(ScrollTrigger);

    this.section = document.querySelector(".contact-form") as HTMLElement;
    this.title = document.querySelector(".contact-form .section-title") as HTMLElement;
    this.text = document.querySelector(".contact-text") as HTMLElement;

    this.form = document.querySelector("#contactForm") as HTMLElement;
    this.inputName = document.querySelector("#name") as HTMLElement;
    this.inputEmail = document.querySelector("#email") as HTMLElement;
    this.selectInterest = document.querySelector("#interest") as HTMLElement;
    this.textaareaMessage = document.querySelector("#message") as HTMLElement;
    this.submitButton = document.querySelector("#contactForm button[type=submit]") as HTMLElement;

    this.whatsappLink = document.querySelector(".whatsapp") as HTMLElement;

    this.init();
  }

  public init(): void {
    if (!this.inputName) return;
    console.log("ContactMotion init");

    const distanceBetween = 56;

    gsap.set([this.inputName, this.inputEmail, this.selectInterest, this.textaareaMessage, this.submitButton], {
      width: 150,
    });

    gsap.to(this.inputName, {
      scrollTrigger: {
        trigger: this.section,
        markers: false,
        start: "top 70%",
        endTrigger: this.inputName,
        end: "bottom 70%",
        toggleActions: "play pause reverse pause",
        scrub: 1,
      },
      width: "100%",
      duration: 2,
    });

    gsap.to(this.inputEmail, {
      scrollTrigger: {
      trigger: this.section,
      markers: false,
      start: `top+=${distanceBetween} 70%`,
      endTrigger: this.inputEmail,
      end: "bottom 70%",
      toggleActions: "play pause reverse pause",
      scrub: 1,
      },
      width: "100%",
      duration: 2,
    });

    gsap.to(this.selectInterest, {
      scrollTrigger: {
      trigger: this.section,
      markers: false,
      start: `top+=${distanceBetween * 2} 70%`,
      endTrigger: this.selectInterest,
      end: "bottom 70%",
      toggleActions: "play pause reverse pause",
      scrub: 1,
      },
      width: "100%",
      duration: 2,
    });

    gsap.to(this.textaareaMessage, {
      scrollTrigger: {
      trigger: this.section,
      markers: false,
      start: `top+=${distanceBetween * 3} 70%`,
      endTrigger: this.textaareaMessage,
      end: "bottom 70%",
      toggleActions: "play pause reverse pause",
      scrub: 1,
      },
      width: "100%",
      duration: 2,
    });

    gsap.to(this.submitButton, {
      scrollTrigger: {
      trigger: this.section,
      markers: false,
      start: `top+=${distanceBetween * 4} 70%`,
      endTrigger: this.submitButton,
      end: "bottom 70%",
      toggleActions: "play pause reverse pause",
      scrub: 1,
      },
      width: "100%",
      duration: 2,
    });
  }
}

export default ContactMotion;
