import "./styles.scss";
import LanguageMenu from "./scripts/effects/language-menu";
import ExperienceTextEffect from "./scripts/effects/experience-text-effect";
import { copyrightYear } from "./scripts/effects/footer";
import CardsMotion from "./scripts/effects/cards-motion";
import Whatsapp from "./scripts/effects/whatsapp";
import ContactMotion from "./scripts/effects/contact-motion";
import ParallaxBackground from "./scripts/effects/parallax-background";
import About from "./scripts/effects/about";
import FirebaseConfig from "./scripts/firebase-config";
import { FormService } from "./scripts/services/form.service";
import { ManageFrom } from "./scripts/form/manage-form";
import SvgBackgroundLoader from "./scripts/effects/svg-background-loader";
import InsertDemos from "./scripts/dynamic-demos/insert-demos";
import { importDemos } from "./demos-data/deploy-demos-firestore";

// Inicializar el selector de idiomas cuando el DOM esté listo
document.addEventListener("DOMContentLoaded", () => {
  copyrightYear();
  const firebaseConfig = new FirebaseConfig();
  // const analytics = new AnalyticsService(firebaseConfig.getCreatedAnalytics());
  const formService = new FormService(firebaseConfig.getFirestoreApp());
  const manageForm = new ManageFrom(formService);

  const experienceText = new ExperienceTextEffect();
  const languageMenu = new LanguageMenu("language-menu", experienceText);
  const insertDemos = new InsertDemos(firebaseConfig.getFirestoreApp(), languageMenu);

  const cardsMotion = new CardsMotion(firebaseConfig.getCreatedAnalytics());
  const whatsapp = new Whatsapp();
  const contactForm = new ContactMotion();
  const svgLoader = new SvgBackgroundLoader(".parallax-bg");
  const parallaxBackground = new ParallaxBackground(".header", ".parallax-bg", {
    scrollSpeed: 400,
    start: "top-=50 top",
    end: "bottom top-=50",
    scrub: 1,
  });
  const about = new About();

  // importDemos();
});
