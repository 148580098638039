import {
  Firestore,
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";

export interface Form {
  id?: string;
  name: string;
  email: string;
  interest: 'aiAutomation' | 'cloudEngineering' | 'web3' | 'training';
  message: string;
  createdDate: Date;
}

export class FormService {
  private collectionName: string;

  constructor(private firestoreInstance: Firestore) {
    this.collectionName = "formularios";
  }

  // Crear un nuevo formulario
  async createForm(form: Omit<Form, "id" | "createDate">): Promise<string> {
    console.log('Creando formulario')
    try {
      const fomrComplete: Omit<Form, "id"> = {
        ...form,
        createdDate: new Date(),
      };

      const docRef = await addDoc(
        collection(this.firestoreInstance, this.collectionName),
        fomrComplete
      );

      return docRef.id;
    } catch (error) {
      console.error("Error creating form: ", error);
      throw error;
    }
  }

  // Obtener todos los formularios
  async getForms(): Promise<Form[]> {
    try {
      const q = collection(this.firestoreInstance, this.collectionName);
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map(doc=>({
        id: doc.id,
        ...doc.data()
      } as Form));
    } catch (error) {
      console.error("Error getting forms: ", error);
      throw error;
    }
  }

  // Eliminar un formulario
  async deleteForm(id: string): Promise<void> {
    try {
      const docRef = doc(this.firestoreInstance, this.collectionName, id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting form: ", error);
      throw error;
    }
  }
}
